import React from "react";
import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import Page from "../components/Page";

const ContractConditions = () => {
  const { t } = useTranslation();

  return (
    <Page title="Contract conditions" description="General contract conditions">
      <Container>
        <section className="section" id="cookies-policy">
          <h3>{t("legal-section.contract-conditions-page.title")}</h3>

          <p>{t("legal-section.contract-conditions-page.introduction")}</p>

          <h6>{t("legal-section.contract-conditions-page.pre-title")}</h6>

          <p>{t("legal-section.contract-conditions-page.pre-line1")}</p>
          <p>{t("legal-section.contract-conditions-page.pre-line2")}</p>
          <p>{t("legal-section.contract-conditions-page.pre-line3")}</p>
          <p>{t("legal-section.contract-conditions-page.pre-line4")}</p>
          <p>{t("legal-section.contract-conditions-page.pre-line5")}</p>
          <p>{t("legal-section.contract-conditions-page.pre-line6")}</p>
          <p>{t("legal-section.contract-conditions-page.pre-line7")}</p>
          <p>{t("legal-section.contract-conditions-page.pre-line8")}</p>
          <p>{t("legal-section.contract-conditions-page.pre-line9")}</p>
          <p>{t("legal-section.contract-conditions-page.pre-line10")}</p>
          <p>{t("legal-section.contract-conditions-page.pre-line11")}</p>

          <h6>
            {t("legal-section.contract-conditions-page.details-seller-title")}
          </h6>

          <p>
            {t("legal-section.contract-conditions-page.details-seller-line1")}
          </p>
          <p>
            {t("legal-section.contract-conditions-page.details-seller-line2")}
          </p>
          <p>
            {t("legal-section.contract-conditions-page.details-seller-line3")}
          </p>
          <p>
            {t("legal-section.contract-conditions-page.details-seller-line4")}
          </p>
          <p>
            {t("legal-section.contract-conditions-page.details-seller-line5")}
          </p>
          <p>
            {t("legal-section.contract-conditions-page.details-seller-line6")}
          </p>
          <p>
            {t("legal-section.contract-conditions-page.details-seller-line7")}
          </p>

          <h6>{t("legal-section.contract-conditions-page.purpose-title")}</h6>

          <p>{t("legal-section.contract-conditions-page.purpose-line1")}</p>
          <p>{t("legal-section.contract-conditions-page.purpose-line2")}</p>
          <p>{t("legal-section.contract-conditions-page.purpose-line3")}</p>
          <p>{t("legal-section.contract-conditions-page.purpose-line4")}</p>
          <p>{t("legal-section.contract-conditions-page.purpose-line5")}</p>
          <p>{t("legal-section.contract-conditions-page.purpose-line6")}</p>

          <h6>
            {t("legal-section.contract-conditions-page.purchasing-title")}
          </h6>

          <p>{t("legal-section.contract-conditions-page.purchasing-line1")}</p>
          <p>{t("legal-section.contract-conditions-page.purchasing-line2")}</p>
          <p>{t("legal-section.contract-conditions-page.purchasing-line3")}</p>
          <ol>
            <li>
              <p>
                {t("legal-section.contract-conditions-page.purchasing-line4")}
              </p>
              <p>
                {t("legal-section.contract-conditions-page.purchasing-line5")}
              </p>
              <p>
                {t("legal-section.contract-conditions-page.purchasing-line6")}
              </p>
              <p>
                {t("legal-section.contract-conditions-page.purchasing-line7")}
              </p>
            </li>
            <li>
              <p>
                {t("legal-section.contract-conditions-page.purchasing-line8")}
              </p>
              <p>
                {t("legal-section.contract-conditions-page.purchasing-line9")}
              </p>
              <p>
                {t("legal-section.contract-conditions-page.purchasing-line10")}
              </p>
            </li>
            <li>
              <p>
                {t("legal-section.contract-conditions-page.purchasing-line11")}
              </p>
              <ul>
                <li>
                  {t(
                    "legal-section.contract-conditions-page.purchasing-line12"
                  )}
                </li>
                <li>
                  {t(
                    "legal-section.contract-conditions-page.purchasing-line13"
                  )}
                </li>
              </ul>
            </li>
            <li>
              <p>
                {t("legal-section.contract-conditions-page.purchasing-line14")}
              </p>
              <p>
                {t("legal-section.contract-conditions-page.purchasing-line15")}
              </p>
            </li>
            <li>
              {t("legal-section.contract-conditions-page.purchasing-line16")}
            </li>
          </ol>

          <h6>{t("legal-section.contract-conditions-page.service-title")}</h6>

          <p>{t("legal-section.contract-conditions-page.service-line1")}</p>

          <h6>{t("legal-section.contract-conditions-page.price-title")}</h6>

          <p>{t("legal-section.contract-conditions-page.price-line1")}</p>
          <p>{t("legal-section.contract-conditions-page.price-line2")}</p>
          <p>{t("legal-section.contract-conditions-page.price-line3")}</p>
          <p>{t("legal-section.contract-conditions-page.price-line4")}</p>
          <p>{t("legal-section.contract-conditions-page.price-line5")}</p>

          <h6>{t("legal-section.contract-conditions-page.offers-title")}</h6>

          <p>{t("legal-section.contract-conditions-page.offers-line1")}</p>

          <h6>{t("legal-section.contract-conditions-page.right-title")}</h6>

          <p>{t("legal-section.contract-conditions-page.right-line1")}</p>
          <p>{t("legal-section.contract-conditions-page.right-line2")}</p>
          <p>{t("legal-section.contract-conditions-page.right-line3")}</p>
          <ul>
            <li>{t("legal-section.contract-conditions-page.right-line4")}</li>
            <li>
              <p>{t("legal-section.contract-conditions-page.right-line5")}</p>
              <p>{t("legal-section.contract-conditions-page.right-line6")}</p>
              <p>{t("legal-section.contract-conditions-page.right-line7")}</p>
              <p>{t("legal-section.contract-conditions-page.right-line8")}</p>
              <p>{t("legal-section.contract-conditions-page.right-line9")}</p>
              <p>{t("legal-section.contract-conditions-page.right-line10")}</p>
              <p>{t("legal-section.contract-conditions-page.right-line11")}</p>
              <p>{t("legal-section.contract-conditions-page.right-line12")}</p>
            </li>
          </ul>

          <h6>
            {t("legal-section.contract-conditions-page.professionals-title")}
          </h6>

          <p>
            {t("legal-section.contract-conditions-page.professionals-line1")}
          </p>

          <h6>{t("legal-section.contract-conditions-page.execution-title")}</h6>

          <p>{t("legal-section.contract-conditions-page.execution-line1")}</p>

          <h6>{t("legal-section.contract-conditions-page.amendment-title")}</h6>

          <p>{t("legal-section.contract-conditions-page.amendment-line1")}</p>
          <p>{t("legal-section.contract-conditions-page.amendment-line2")}</p>

          <h6>{t("legal-section.contract-conditions-page.liability-title")}</h6>

          <p>{t("legal-section.contract-conditions-page.liability-line1")}</p>
          <p>{t("legal-section.contract-conditions-page.liability-line2")}</p>
          <p>{t("legal-section.contract-conditions-page.liability-line3")}</p>

          <h6>
            {t("legal-section.contract-conditions-page.protection-title")}
          </h6>

          <p>{t("legal-section.contract-conditions-page.protection-line1")}</p>
          <p>{t("legal-section.contract-conditions-page.protection-line2")}</p>

          <h6>
            {t("legal-section.contract-conditions-page.applicable-title")}
          </h6>

          <p>{t("legal-section.contract-conditions-page.applicable-line1")}</p>

          <h6>{t("legal-section.contract-conditions-page.personal-title")}</h6>

          <p>{t("legal-section.contract-conditions-page.personal-line1")}</p>
        </section>
      </Container>
    </Page>
  );
};

export default ContractConditions;
